import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {NbAccessChecker} from '@nebular/security';
import {collectPathParams, collectQueryParams} from 'app/@core/utils/request.helper';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class MainAuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: NbAuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticatedOrRefresh()
    .pipe(
      tap(authenticated => {
        if (!authenticated) {
          const url = state.url.split('?')[0];
          const params = collectQueryParams(next);
          this.router.navigate(['auth/login'], {
            queryParams: {
              from: encodeURIComponent(url),
              params: encodeURIComponent(JSON.stringify(Object.fromEntries(params)))
            }
          });
          return false;
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticatedOrRefresh()
    .pipe(
      tap(authenticated => {
        if (!authenticated) {
          const url = state.url.split('?')[0];
          const params = collectQueryParams(childRoute);
          this.router.navigate(['auth/login'], {
            queryParams: {
              from: encodeURIComponent(url),
              params: encodeURIComponent(JSON.stringify(Object.fromEntries(params)))
            }
          });
          return false;
        }
        return true;
      })
    );
  }
}

@Injectable()
export class RoleAuthGuard implements CanActivate {
  constructor(private authService: NbAuthService,
              private router: Router,
              private accessChecker: NbAccessChecker) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    let url = state.url;
    const params: Map<string, string> = collectPathParams(next);
    params.forEach(paramValue => {
      url = url.replace(paramValue, '*');
    });
    url = url.split('?')[0];
    return this.accessChecker.isGranted('url', url)
    .pipe(
      tap(isGranted => {
        if (!isGranted) {
          this.router.navigate(['pages/404']);
          return false;
        }
        return true;
      })
    );
  }
}

