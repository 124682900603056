import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from 'app/@core/utils/user.service';
import {CachingInterceptor} from 'app/@core/utils/caching.interceptor';
import {RequestCacheService} from 'app/@core/utils/request-cache.service';
import {CoreModule} from './@core/core.module';
import {ThemeModule} from './@theme/theme.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainAuthGuard, RoleAuthGuard} from './main-auth-guard.service';
import {AlertService} from './service/alert.service';
import {BackofficeService} from './service/backoffice.service';
import {ErrorInterceptor} from './@core/utils/error.interceptor';
import {JwtInterceptor} from './@core/utils/jwt.interceptor';
import { NbToastrModule } from '@nebular/theme';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule,
    NbToastrModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    RequestCacheService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    MainAuthGuard, RoleAuthGuard,
    AlertService,
    BackofficeService,
    {provide: APP_BASE_HREF, useValue: '/'},
    UserService
  ],
  entryComponents: []
})
export class AppModule {
}
