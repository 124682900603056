import {Component, OnInit} from '@angular/core';
import {Validator} from '../dynamic-field';
import {DateAbstract} from './date.abstract';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styles: []
})
export class DateComponent extends DateAbstract implements OnInit {
  type = DateComponent;
  name: string;
  label: string;
  validations: Validator[] = [];
  required: boolean;
  styles?: any = {};

  ngOnInit() {
  }

  hasValidationError(validation: Validator): boolean {
    return this.group.get(this.name).hasError(validation.name);
  }
}
