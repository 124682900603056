import {Injectable, OnDestroy} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {NbMenuItem} from '@nebular/theme';
import {AuthADFSToken} from 'app/@core/utils/internal-auth.strategy';
import {BackendPermissionMenuMap} from 'app/pages/pages-menu';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

@Injectable()
export class UserService implements OnDestroy {

  private authenticatedUser: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject<AuthenticatedUser>(undefined);
  private subscriptions: Subscription[] = [];

  constructor(private authService: NbAuthService) {
    this.subscriptions.push(
      this.authService.isAuthenticatedOrRefresh()
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.authService.onTokenChange().subscribe((token: AuthADFSToken) => {
            if (!token.getPayload()) {
              return; // happens in logout
            }
            const parsedToken = token.getParsedToken();
            const email = parsedToken.sub || '';
            const emailSplit = email.split('@')[0].split('.');
            const firstName = emailSplit[0].charAt(0).toUpperCase();
            let lastName = '';
            if (emailSplit.length > 1) {
              lastName += emailSplit[1].charAt(0).toUpperCase();
              if (emailSplit[1].split('-').length > 1) {
                const lastSplit = emailSplit[1].split('-');
                lastName += lastSplit[0].slice(1).toLowerCase();
                lastName += '-';
                lastName += lastSplit[1].charAt(0).toUpperCase() + lastSplit[1].slice(1).toLowerCase();
              } else {
                lastName += emailSplit[1].slice(1).toLowerCase();
              }
            }
            let displayName = lastName.length > 0 ? firstName + '.' + lastName : emailSplit[0].charAt(0).toUpperCase() + emailSplit[0].slice(1).toLowerCase();
            if (!!parsedToken.firstName && !!parsedToken.lastName) {
              displayName = parsedToken.firstName.charAt(0).toUpperCase();
              displayName += '.';
              displayName += parsedToken.lastName.charAt(0).toUpperCase() + parsedToken.lastName.slice(1).toLowerCase();
            }

            this.authenticatedUser.next({
              displayName: displayName,
              firstName: parsedToken.firstName || emailSplit[0].charAt(0).toUpperCase() + emailSplit[0].slice(1).toLowerCase(),
              lastName: parsedToken.lastName || lastName,
              email: email,
              title: parsedToken.roles
            });
          });
        }
      })
    );
  }

  getAuthenticatedUserInfo(): Observable<AuthenticatedUser> {
    return this.authenticatedUser.asObservable();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
  }
}

export function reduceNotAllowedMenuItems(allowedMenuItems: string[] = [], menu: NbMenuItem[] = []): NbMenuItem[] {
  const availableLinks = ['rebt', ...allowedMenuItems]
  .filter(p => BackendPermissionMenuMap[p] !== undefined)
  .map(p => BackendPermissionMenuMap[p]);

  return menu.reduce((m, item) => {
    if (item.children !== undefined) {
      item.children = item.children.reduce((childrens, child) => {
        if (child.link !== undefined && availableLinks.indexOf(child.link) !== -1) {
          childrens.push(child);
        }
        return childrens;
      }, []);
      if (item.children.length > 0) {
        m.push(item);
      }
    }
    if (item.data !== undefined) {
      m.push(item);
    }
    if (item.link !== undefined && availableLinks.indexOf(item.link) !== -1) {
      m.push(item);
    }
    return m;
  }, []);
}

export interface AuthenticatedUser {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
}
