import {ActivatedRouteSnapshot} from '@angular/router';

export interface PageableRequestParametersDto {
  pageIndex?: number;
  pageSize?: number;
  sortParam?: string;
  direction?: string;
}

export interface PageableRequestParameters {
  page: number;
  size: number;
  sort: string | undefined;
}

export function createQueryParams(params: { [key: string]: (string | number) }): string {
  const queryParams = new URLSearchParams();

  Object.entries(params)
  .filter(([, value]) => !!value)
  .forEach(([key, value]) => queryParams.set(key, value as string));

  return queryParams.toString();
}

export function convertToSortParams(params: PageableRequestParametersDto): { [key: string]: (string | number) } {
  return {
    page: params.pageIndex || 0,
    size: params.pageSize || 20,
    sort: params.sortParam ? `${params.sortParam},${params.direction}` : undefined
  };
}

export function collectPathParams(snapshot: ActivatedRouteSnapshot): Map<string, string> {
  const map = new Map<string, string>();
  snapshot.paramMap.keys.map((key: string) => {
    map.set(key, snapshot.paramMap.get(key));
  });
  return !!snapshot.firstChild ? new Map<string, string>([...map, ...collectPathParams(snapshot.firstChild)]) : map;
}

export function collectQueryParams(snapshot: ActivatedRouteSnapshot): Map<string, string> {
  const map = new Map<string, string>();
  snapshot.queryParamMap.keys.map((key: string) => {
    map.set(key, snapshot.queryParamMap.get(key));
  });
  return !!snapshot.firstChild ? new Map<string, string>([...map, ...collectQueryParams(snapshot.firstChild)]) : map;
}
