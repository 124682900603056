import {Component} from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <!--<span class="created-by"><b>Service Maintenance and Wear</b></span>-->
  `
})
export class FooterComponent {
}
