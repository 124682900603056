import {Input, Type} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig, Validator} from '../dynamic-field';

export abstract class InputAbstract implements FieldConfig {
  abstract type: Type<InputAbstract>;
  @Input() group: FormGroup;
  abstract name: string;
  abstract label: string;
  abstract inputType: string;
  abstract maxLength?: number;
  abstract minLength?: number;
  abstract validations?: Validator[] = [];
  abstract disabled?: boolean;
}
