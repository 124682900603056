import {Component, Input, OnInit} from '@angular/core';
import {Validator} from '../dynamic-field';
import {InputAbstract} from './input.abstract';

@Component({
  selector: 'app-input',
  template: `
    <mat-form-field
      class="container-fluid mt-auto"
      [style.margin]="styles.margin"
      [style.padding]="styles.padding"
      [style.width]="styles.width"
      [formGroup]="group">
      <input matInput
             [formControlName]="name"
             [placeholder]="label"
             [type]="inputType"
             [maxlength]="maxLength"
             [minlength]="minLength"
      >
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error
            *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
      <mat-error *ngIf="group.get(name).hasError('custom')">{{group.get(name).getError(
        'custom')}}</mat-error>
    </mat-form-field>
  `,
  styles: []
})
export class InputComponent extends InputAbstract implements OnInit {
  @Input() type = InputComponent;
  @Input() name: string;
  @Input() label: string;
  @Input() inputType: string;
  @Input() maxLength = 0;
  @Input() minLength = 0;
  @Input() validations: Validator[] = [];
  @Input() disabled = false;
  styles?: any = {};

  ngOnInit() {
  }
}
