import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NbAuthService, NbAuthToken} from '@nebular/auth';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: NbAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap((token: NbAuthToken) => {
        const JWT = `Bearer ${token.getValue()}`;
        request = request.clone({
          setHeaders: {
            Authorization: JWT
          }
        });
        return next.handle(request);
      })
    );
  }
}
