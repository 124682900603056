import {Component, OnInit} from '@angular/core';
import {Validator} from '../dynamic-field';
import {InputAbstract} from './input.abstract';

@Component({
  selector: 'app-number-input',
  template: `
    <mat-form-field class="container-fluid mt-auto" [formGroup]="group">
      <input matInput
             [formControlName]="name"
             [placeholder]="label"
             [maxlength]="maxLength"
             [minlength]="minLength"
             (keydown)="onKeypress($event)"
      >
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: []
})
export class NumberInputComponent extends InputAbstract implements OnInit {
  type = NumberInputComponent;
  name: string;
  label: string;
  inputType: string;
  maxLength = 0;
  minLength = 0;
  validations: Validator[] = [];
  disabled = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  onKeypress(event) {
    const initialValue = event.key || '';
    const afterCheck = initialValue.replace(/[^0-9]*/g, '');
    if (initialValue !== afterCheck || this.group.get(this.name).value) {
      const control = this.group.get(this.name);
      const value = control.value || '';
      this.group.get(this.name).markAsTouched();
      this.group.get(this.name).patchValue(value.replace(/[^0-9]*/g, ''));
      /*event.preventDefault();*/
    }
  }
}
