import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'form-title',
  template: `
    <h6 class="px-3">
      {{content}}
    </h6>
  `,
  styles: [`
    h6 {
      text-transform: uppercase;
    }
  `]
})
export class FormTitleComponent {
  @Input() type = FormTitleComponent;
  @Input() content: string;
  group: FormGroup;
}
