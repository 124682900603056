import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Validator} from '../dynamic-field';
import {InputAbstract} from './input.abstract';

@Component({
  selector: 'app-re-input',
  template: `
    <div class="position-relative">
      <mat-form-field class="container-fluid mt-auto">
        <input matInput
               [formControl]="control"
               [placeholder]="label"
               [type]="inputType"
               [maxlength]="maxLength"
               [minlength]="minLength"
               fullWidth
        >
        <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
          <mat-error
              *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
        </ng-container>
        <ng-container *ngIf="!!prefix">
          <span matPrefix class="mat-prefix">{{prefix}}</span>
        </ng-container>
        <ng-container *ngIf="!!suffix">
          <span matSuffix class="mat-suffix">{{suffix}}</span>
        </ng-container>
      </mat-form-field>
    </div>
  `,
  styles: [`
    .mat-prefix {
      position: absolute;
      top: 50%;
      left: -12px;
      margin-top: -8px;
    }
    .mat-suffix {
      margin-left: -3rem;
    }
  `]
})
export class ReusableInputComponent extends InputAbstract implements OnInit {
  @Input() type = ReusableInputComponent;
  @Input() control: FormControl;
  @Input() label: string;
  @Input() inputType: string;
  @Input() maxLength = 0;
  @Input() minLength = 0;
  @Input() validations: Validator[] = [];
  @Input() disabled = false;
  @Input() name: string;
  @Input() prefix: string;
  @Input() suffix: string;

  ngOnInit(): void {
    this.control = this.group.get(this.name) as FormControl;
  }
}
