import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ButtonComponent} from '../button';
import {CardComponent} from '../card';
import {ContainerComponent, ListContainerComponent} from '../container';
import {FieldConfig} from '../dynamic-field';

@Component({
  exportAs: 'dynamicForm',
  selector: 'app-dynamic-form',
  template: `
    <form class="container-fluid" [formGroup]="form" (submit)="onSubmit($event)">
      <div class="row">
        <ng-container *ngFor="let formPart of fields; let i = index">
          <ng-container *ngIf="isArray(formPart)">
            <div [ngClass]="getSize(fields, i)">
              <ng-container *ngFor="let item of formPart;">
                <ng-container *ngIf="isArray(item)">
                  <div class="row">
                    <ng-container *ngFor="let field of item;">
                      <div>
                        <ng-container appDynamicField [field]="field" [group]="resolveFormForNestedComponent(field)">
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[][][] = [];
  @Input() fieldsGroupConfig?: Array<{ rowCols: number; }>;

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  get value() {
    const validForm = Object.keys(this.form.value)
      .filter(key => key !== 'undefined' && key !== 'null')
      .reduce((acc, next) => {
        return {
          ...acc,
          [next]: this.form.value[next]
        };
      }, {});

    return this.formatDates(validForm);
  }

  getSize(fields, fieldsGroupIndex) {
    if (this.fieldsGroupConfig) {
      return `col-${this.fieldsGroupConfig[fieldsGroupIndex].rowCols}`;
    }

    const size = Math.ceil(12 / fields.length);

    return `col-${size || 12}`;
  }

  ngOnInit() {
    this.form = this.createControls(this.fields);
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.form.valid) {
      this.submit.emit(this.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControls(formRow: any[]) {
    const formGroup = this.fb.group({});
    formRow.forEach(fields => {
      fields.forEach((group: any[]) => {
        group.forEach((field) => {
          if (field.type === ButtonComponent) {
            return;
          }
          const control = this.createFieldControl(field);
          if (field.type === CardComponent) {
            for (const item in
              (<FormGroup>control).controls) {
              if ((<FormGroup>control).controls[item] instanceof FormGroup) {
                formGroup.addControl(item, (<FormGroup>control).controls[item]);
              }
            }
            return;
          }
          formGroup.addControl(field.name, control);
        });
      });
    });
    return formGroup;
  }

  createFieldControl(field: any): AbstractControl {
    let control: AbstractControl;
    if (field.type === ContainerComponent) {
      control = this.createControls([field.items]);
    } else if (field.type === CardComponent) {
      control = this.createControls([field.childItems]);
    } else if (field.type === ListContainerComponent) {
      control = this.fb.array([]);
      field.items.forEach((item) => {
        const itemControl = this.createFieldControl(item);
        (<FormArray>control).push(itemControl);
      });
    } else if (field.type !== ButtonComponent) {
      const validations = field.validations || [];
      control = this.fb.control(
        {
          value: typeof field.value === 'undefined' ? '' : field.value,
          disabled: typeof field.disabled === 'undefined' ? false : field.disabled
        },
        this.bindValidations(validations)
      );
    }
    return control;
  }

  private bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
      control.markAsTouched({ onlySelf: true });
    });
  }

  isArray(val: any) {
    return Array.isArray(val);
  }

  resolveFormForNestedComponent(field: any): AbstractControl {
    return field.type === ContainerComponent ? this.form.controls[field.name] : this.form;
  }

  private formatDates(origin) {
    const values = Object.assign({}, origin);
    Object.entries(values).forEach(([key, value]) => {
      if (value instanceof moment) {
        // @ts-ignore
        values[key] = <moment>value.format('YYYY-MM-DD');
      } else if (value === null) {
        delete values[key];
      } else if (typeof value === 'object') {
        let isEmpty = true;
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (subValue.length !== 0) {
            isEmpty = false;
          } else {
            delete values[key][subKey];
          }
        });
        if (isEmpty) {
          delete values[key];
        }
      }
    });
    return values;
  }
}
