import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NbEvaIconsModule} from '@nebular/eva-icons';

import {NbSecurityModule} from '@nebular/security';

import {
  NbAccordionModule,
  NbActionsModule,
  NbToggleModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbThemeModule,
  NbToastrModule,
  NbTooltipModule,
  NbUserModule,
  NbWindowModule
} from '@nebular/theme';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MaterialModule} from '../material.module';

import {
  AlertComponent,
  BaseComponent,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  ContainerComponent,
  DateComponent,
  DynamicFieldDirective,
  DynamicFormComponent,
  FileUploaderComponent,
  FooterComponent,
  HeaderComponent,
  InputComponent,
  ReusableInputComponent,
  ListContainerComponent,
  ListInputComponent,
  NumberInputComponent,
  SelectComponent,
  TabComponent,
  TabsetComponent,
  TextareaComponent,
  ThemeSettingsComponent,
  TinyMCEComponent,
  FormTitleComponent,
} from './components';
import {AngularFileUploaderModule} from './components/angular-file-uploader/angular-file-uploader.module';

import {SampleLayoutComponent} from './layouts';

import {
  CapitalizePipe,
  EvaIconsPipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe
} from './pipes';

import {CORPORATE_THEME} from './styles/theme.corporate';
import { RouterModule } from '@angular/router';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, RouterModule];

const NB_MODULES = [
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbToggleModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  AngularFileUploaderModule,
  NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbChatModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbBadgeModule
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  ThemeSettingsComponent,
  SampleLayoutComponent,
  BaseComponent,
  TinyMCEComponent,
  AlertComponent,
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  ContainerComponent,
  ListContainerComponent,
  DateComponent,
  DynamicFieldDirective,
  DynamicFormComponent,
  InputComponent,
  TextareaComponent,
  ListInputComponent,
  NumberInputComponent,
  SelectComponent,
  TabComponent,
  TabsetComponent,
  FileUploaderComponent,
  ReusableInputComponent,
  FormTitleComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'corporate'
    },
    [CORPORATE_THEME]
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
  entryComponents: [
    InputComponent,
    NumberInputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    ContainerComponent,
    ListContainerComponent,
    ListInputComponent,
    CheckboxComponent,
    CardComponent,
    FileUploaderComponent,
    TextareaComponent,
    FormTitleComponent,
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS]
    };
  }
}
