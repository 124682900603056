import {Injectable} from '@angular/core';
import {decodeJwtPayload, NbAuthService} from '@nebular/auth';
import {NbRoleProvider} from '@nebular/security';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthADFSToken} from './internal-auth.strategy';

@Injectable()
export class SMWRoleProvider extends NbRoleProvider {
  constructor(private authService: NbAuthService) {
    super();
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange()
    .pipe(
      map((token: AuthADFSToken) => {
        if (!token.isValid()) {
          return 'guest';
        }
        const payload = token.getValue() || '';
        const decoded = decodeJwtPayload(payload);
        return decoded.roles.toLowerCase();
      })
    );
  }
}
