import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import { NbToastRef, NbToastrService } from '@nebular/theme';
import {Observable, Subject, Subscription} from 'rxjs';

export enum AlertMessages {
  SUCCESS = 'success',
  DANGER = 'danger',
  ERROR = 'error',
  CLEAR = 'clear',
}

export interface AlertMessage {
  type: AlertMessages.SUCCESS | AlertMessages.DANGER | AlertMessages.ERROR | AlertMessages.CLEAR;
  text?: string;
}

@Injectable({providedIn: 'root'})
export class AlertService implements OnDestroy {
  private subject = new Subject<AlertMessage>();
  private keepAfterNavigationChange = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private toastr: NbToastrService,
  ) {
    // clear alert message on route change
    this.subscriptions.push(
      router.events
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          if (this.keepAfterNavigationChange) {
            // only keep for a single location change
            this.keepAfterNavigationChange = false;
          } else {
            // clear alert
            this.subject.next();
          }
        }
      })
    );
  }

  success(message: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showToast(message, '', 'success');
    this.subject.next({type: AlertMessages.SUCCESS, text: message});
  }

  danger(msg: string | HttpErrorResponse, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    const {message, details} = this.createUIMessage(msg);
    this.showToast(message, details, 'danger');
    this.subject.next({type: AlertMessages.DANGER, text: message});
  }

  error(msg: string, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    const {message, details} = this.createUIMessage(msg);
    this.showToast(message, details, 'danger');
    this.subject.next({type: AlertMessages.ERROR, text: message});
  }

  private createUIMessage(message: string | HttpErrorResponse): { message: string, details: string } {
    if (typeof message === 'object') {
      const uiMessage = message.error.message || message.statusText;
      let details = '';
      if (message.error.fieldConstraints) {
        let ctr = 1;
        details = message.error.fieldConstraints.map(x => `${ctr++}: ${x.message}`).join('\n');
      }
      return {
        message: uiMessage,
        details: details
      };
    } else {
      return {
        message: message,
        details: ''
      };
    }
  }

  showToast(message: string, details: string, status: 'danger' | 'success') {
    this.toastr.show(details, message, {
      duration: status === 'success' ? 3000 : 0,
      status: status,
      hasIcon: true,
      icon: {
        icon: 'close-outline'
      }
    });
  }

  clear() {
    this.subject.next({type: AlertMessages.CLEAR});
  }

  getMessage(): Observable<AlertMessage> {
    return this.subject.asObservable();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
