import {Component} from '@angular/core';
import {FieldConfig} from '../dynamic-field';
import {SelectOptionInterface} from '../select';
import {FormGroup, AbstractControl} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-base-component',
  template: 'NO UI TO BE FOUND HERE!'
})
export class BaseComponent {
  static create(config: FieldConfig): FieldConfig {
    return Object.assign(new this(), config);
  }

  static enumToSelectOptionInterface(enumerated): SelectOptionInterface[] {
    const options: SelectOptionInterface[] = [];
    const keys = Object.keys(enumerated);
    for (const key of keys) {
      options.push(
        {
          title: key.replace(/_/g, ' '),
          value: enumerated[key]
        }
      );
    }
    return options;
  }

  static handleBackendValidationErrors(form: FormGroup, error: HttpErrorResponse, mapping: { [key: string]: string } = {}): void {
    if (error.error && error.error.fieldConstraints) {
      const constraints: [] = error.error.fieldConstraints;
      constraints.forEach((er: { fieldPath: string, rejectedValue: string, message: string }) => {
        const path = er.fieldPath.split('.');
        let el: FormGroup | AbstractControl = form;
        path.forEach((pathPart) => {
          pathPart = mapping[pathPart] || pathPart;

          if (el !== undefined) {
            el = el.get(pathPart);
          }
        });
        if (el !== undefined) {
          el.setErrors({ 'custom': er.message });
        }
      });
    }
  }
}

