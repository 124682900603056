import {Type} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FieldConfig, Validator} from '../dynamic-field';
import {SelectOptionInterface} from './select-option.interface';

export abstract class SelectAbstract implements FieldConfig {
  abstract type: Type<SelectAbstract>;
  group: FormGroup;
  abstract label: string;
  abstract name: string;
  abstract options: SelectOptionInterface[];
  abstract validations: Validator[];
}
