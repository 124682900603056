import {Component} from '@angular/core';
import {Validator} from '../dynamic-field';
import {TextareaAbstract} from './textarea.abstract';

@Component({
  selector: 'app-textarea',
  template: `
    <mat-form-field [style.margin]="styles.margin"
                    [style.padding]="styles.padding"
                    [style.width]="styles.width"
                    class="container-fluid mt-auto"
                    [formGroup]="group">
      <textarea matInput
                [formControlName]="name"
                [placeholder]="label"
      >
      </textarea>
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
      <mat-error *ngIf="group.get(name).hasError('custom')">{{group.get(name).getError(
        'custom')}}</mat-error>
    </mat-form-field>
  `,
  styles: []
})
export class TextareaComponent extends TextareaAbstract {
  type = TextareaComponent;
  name: string;
  label: string;
  validations: Validator[] = [];
  disabled = false;
  styles?: any = {};
}
