<div class="header-container">
  <div class="logo-container-header">
    <a (click)="toggleSidebar()" class="navigation" href="#">
      <nb-icon icon="menu-outline"></nb-icon>
    </a>
    <div class="logo-group-header">
      <div [routerLink]="'/pages/dashboard'" class="sixt-logo-header logo"></div>
    </div>
  </div>
</div>
<div class="header-container">

</div>
<div class="header-container">
  <nb-actions
    [class.right]="true"
    size="medium">
    <nb-action *nbIsGranted="['view', 'userProfileMenu']">
      <div class="user-icon">
        <i class="fas fa-user"></i>
      </div>
      <nb-user [name]="user.displayName" [nbContextMenu]="userMenu" showInitials="false"
               showName="true"></nb-user>
    </nb-action>
    <!--<nb-action class="control-item" icon="nb-email"></nb-action>-->
    <!--    <nb-action class="control-item">-->
    <!--<nb-search type="rotate-layout" (click)="startSearch()"></nb-search>-->
    <!--    </nb-action>-->
  </nb-actions>
</div>
