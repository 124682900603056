import {NbMenuItem} from '@nebular/theme';

export const MENU_ITEMS_SUPPORT: NbMenuItem[] = [
  {
    title: 'CLOSE',
    icon: 'close-outline',
    data: 'collapseMenu'
  },
  {
    title: 'HOME',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true
  },
  {
    title: 'AUDIT TOOL',
    icon: 'search-outline',
    link: '/audit'
  }
];
export const MENU_ITEMS_PRICING_MANAGER: NbMenuItem[] = [
  {
    title: 'CALCULATION PARAMETERS',
    icon: 'smartphone-outline',
    link: '/calculation-parameters'
  },
  {
    title: 'VALIDATION RULES',
    icon: 'checkmark-square-outline',
    link: '/validation-rules'
  },
  {
    title: 'TECHALLIANCE PRICING DATA',
    icon: 'pricetags-outline',
    link: '/confirm-ta-pricing',
  },
  {
    title: 'HISTORY',
    icon: 'book-open-outline',
    link: '/history'
  },
  {
    title: 'KW-MATRIX',
    icon: 'settings-2-outline',
    link: '/rebt'
  },
  {
    title: 'USER MANAGEMENT',
    icon: 'lock-outline',
    link: '/user-management/internal'
  }
];

export class BackendPermissionMenuMap {
  static menuCalculationParameters = '/calculation-parameters';
  static menuValidationConfigs = '/validation-rules';
  static menuPricingDataTA = '/confirm-ta-pricing';
  static menuAuditTool = '/audit';
  static menuDashboard = '/pages/dashboard';
  static menuUserLogHistory = '/history';
  static menuUserManagement = '/user-management/internal';
  static rebt = '/rebt';
}
