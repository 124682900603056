<div *ngIf="(theme !== 'attachPin')" class="container-fluid" id="default">

  <!-- Drag n Drop theme Starts -->
  <div *ngIf="theme == 'dragNDrop'"
       [ngClass]="(hideSelectBtn && hideResetBtn) ? null : 'dragNDropBtmPad'"
       class="dragNDrop" id="dragNDrop">
    <div style="position:relative;">
      <div (dragover)="allowDrop($event)" (drop)="drop($event)" class="div1 afu-dragndrop-box"
           id="div1">
        <p class="afu-dragndrop-text">{{replaceTexts?.dragNDropBox}}</p>
      </div>
      <!-- <span class='label label-info' id="upload-file-info{{id}}">{{selectedFiles[0]?.name}}</span> -->
    </div>
  </div>
  <!-- Drag n Drop theme Ends -->

  <label *ngIf="!hideSelectBtn" class="btn btn-primary btn-md afu-select-btn rounded-0"
         for="sel{{id}}">{{replaceTexts?.selectFileBtn}}</label>
  <input (change)="onChange($event)" *ngIf="!hideSelectBtn" [accept]=formatsAllowed
         [attr.multiple]="multiple ? '' : null"
         id="sel{{id}}"
         name="files[]"
         style="display: none" title="Select file" type="file"/>
  <button (click)="resetFileUpload()" *ngIf="!hideResetBtn"
          class="btn btn-info btn-md resetBtn afu-reset-btn">{{replaceTexts?.resetBtn}}</button>
  <br *ngIf="!hideSelectBtn">
  <p class="constraints-info afu-constraints-info">{{formatsAllowed}} only up to - {{(convertSize(maxSize * 1024000))}}</p>
  <!--Selected file list-->
  <ng-container *ngIf="showFiles">
    <div *ngFor="let sf of selectedFiles;let i=index" class="row afu-valid-file">
      <p class="col-xs-3 textOverflow"><span class="text-primary">{{sf.name}}</span></p>
      <p class="col-xs-3 padMarg sizeC"><strong>({{convertSize(sf.size)}})</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <!--  <input class="col-xs-3 progress caption"  type="text"  placeholder="Caption.."  [(ngModel)]="Caption[i]"  *ngIf="uploadClick"/> -->
      <div *ngIf="singleFile && progressBarShow && !hideProgressBar"
           class="progress col-xs-3 padMarg afu-progress-bar">
      <span [ngStyle]="{'width':percentComplete+'%'}" class="progress-bar progress-bar-success"
            role="progressbar">{{percentComplete}}%</span>
      </div>
      <a (click)="removeFile(i,'sf')" *ngIf="uploadClick" class="col-xs-1" role="button"><i
        class="fa fa-times"></i></a>
    </div>
    <!--Invalid file list-->
    <div *ngFor="let na of notAllowedList;let j=index" class="row text-danger afu-invalid-file">
      <p class="col-xs-3 textOverflow"><span>{{na['fileName']}}</span></p>
      <p class="col-xs-3 padMarg sizeC"><strong>({{na['fileSize']}})</strong></p>
      <p class="col-xs-3 ">{{na['errorMsg']}}</p>
      <a (click)="removeFile(j,'na')" *ngIf="uploadClick" class="col-xs-1 delFileIcon"
         role="button">&nbsp;<i
        class="fa fa-times"></i></a>
    </div>
  </ng-container>

  <p *ngIf="uploadMsg" class="{{uploadMsgClass}} + afu-upload-status">{{uploadMsgText}}
  <p>
  <div *ngIf="!singleFile && progressBarShow && !hideProgressBar">
    <div class="progress col-xs-4 padMarg afu-progress-bar">
      <span [ngStyle]="{'width':percentComplete+'%'}" class="progress-bar progress-bar-success"
            role="progressbar">{{percentComplete}}%</span>
    </div>
    <br>
    <br>
  </div>
  <button (click)="uploadFiles()" [disabled]=!uploadBtn class="btn btn-success afu-upload-btn"
          type="button">{{replaceTexts?.uploadBtn}}</button>
  <br>
</div>

<!--/////////////////////////// ATTACH PIN THEME  //////////////////////////////////////////////////////////-->
<div *ngIf="theme == 'attachPin'" id="attachPin">
  <div style="position:relative;padding-left:6px">
    <a (click)="attachpinOnclick()" class='btn up_btn afu-attach-pin'>
      {{replaceTexts?.attachPinBtn}}
      <i aria-hidden="true" class="fa fa-paperclip"></i>
      <!-- <p style="margin-top:10px">({{formatsAllowed}}) Size limit- {{(convertSize(maxSize * 1024000))}}</p> -->
      <input (change)="onChange($event)" [accept]=formatsAllowed
             [attr.multiple]="multiple ? '' : null" id="sel{{id}}"
             name="files[]"
             style="display: none" title="Select file"
             type="file"/>
      <br>
    </a>
    &nbsp;
    <span class='label label-info' id="upload-file-info{{id}}">{{selectedFiles[0]?.name}}</span>
  </div>
</div>

<!--/////////////////////////// DRAG N DROP THEME  //////////////////////////////////////////////////////////-->
<!-- <div *ngIf="theme == 'dragNDrop'" id="dragNDrop">
  <div style="position:relative;padding-left:6px">
    <div id="div1" (drop)="drop($event)" (dragover)="allowDrop($event)">
      <p>Drag N Drop</p>
    </div>
    <span class='label label-info' id="upload-file-info{{id}}">{{selectedFiles[0]?.name}}</span>
  </div>
</div> -->
