import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {MainAuthGuard} from 'app/main-auth-guard.service';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'user-management',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  {
    path: 'calculation-parameters',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/calculation-parameters/calculation-parameters.module').then(m => m.CalculationParametersModule)
  },
  {
    path: 'validation-rules',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/validation-rules/validation-rules.module').then(m => m.ValidationRulesModule)
  },
  {
    path: 'confirm-ta-pricing',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/confirm-ta-pricing/confirm-ta-pricing.module').then(m => m.ConfirmTAPricingModule)
  },
  {
    path: 'history',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/history/history.module').then(m => m.HistoryModule)
  },
  {
    path: 'rebt',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/rebt/rebt.module').then(m => m.RebtModule)
  },
  {
    path: 'audit',
    canActivate: [MainAuthGuard],
    loadChildren: () => import('app/modules/audit/audit.module').then(m => m.AuditModule)
  },
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'}
];

const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
