import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NbTokenStorage} from '@nebular/auth';
import {collectQueryParams} from 'app/@core/utils/request.helper';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenStorage: NbTokenStorage,
    private route: ActivatedRoute
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        const queryParams = collectQueryParams(this.route.snapshot);
        this.tokenStorage.clear();
        this.router.navigate(['/auth/login'], {
            queryParams: {
              from: encodeURIComponent(this.router.url.split('?')[0]),
              params: encodeURIComponent(JSON.stringify(Object.fromEntries(queryParams)))
            }
          }
        );
      }
      console.log(err);
      // const error = err.error.message || err.statusText;
      return throwError(err);
    }));
  }
}
