import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NbBadgePosition, NbComponentStatus, NbIconConfig} from '@nebular/theme';
import {delay, filter, map} from 'rxjs/operators';

export function convertToBoolProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();

    return (val === 'true' || val === '');
  }

  return !!val;
}

/**
 * Specific tab container.
 *
 * ```ts
 * <app-tab tabTitle="Users"
 *   badgeText="99+"
 *   badgeStatus="danger">
 *   <p>List of <strong>users</strong>.</p>
 * </app-tab>
 ```
 */
@Component({
  selector: 'app-tab',
  template: `
    <ng-container *ngIf="init">
      <ng-content></ng-content>
    </ng-container>
  `
})
export class TabComponent {

  /**
   * Tab title
   */
  @Input() tabTitle: string;

  /**
   * Tab icon name or icon config object
   */
  @Input() tabIcon: string | NbIconConfig;
  @Input() route: string;
  @HostBinding('class.content-active')
  activeValue = false;
  responsiveValue = false;
  disabledValue = false;
  /**
   * Badge text to display
   */
  @Input() badgeText: string;
  /**
   * Badge status (adds specific styles):
   * 'primary', 'info', 'success', 'warning', 'danger'
   */
  @Input() badgeStatus: NbComponentStatus;
  /**
   * Badge position.
   * Can be set to any class or to one of predefined positions:
   * 'top left', 'top right', 'bottom left', 'bottom right',
   * 'top start', 'top end', 'bottom start', 'bottom end'
   */
  @Input() badgePosition: NbBadgePosition;
  init = false;

  /**
   * Item is disabled and cannot be opened.
   */
  @Input('disabled')
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this.disabledValue;
  }

  set disabled(val: boolean) {
    this.disabledValue = convertToBoolProperty(val);
  }

  get responsive() {
    return this.responsiveValue;
  }

  /**
   * Show only icons when width is smaller than `tabs-icon-only-max-width`
   */
  @Input()
  set responsive(val: boolean) {
    this.responsiveValue = convertToBoolProperty(val);
  }

  /**
   * Specifies active tab
   */
  @Input()
  get active() {
    return this.activeValue;
  }

  set active(val: boolean) {
    this.activeValue = convertToBoolProperty(val);
    if (this.activeValue) {
      this.init = true;
    }
  }

  /**
   * Lazy load content before tab selection
   * TODO: rename, as lazy is by default, and this is more `instant load`
   */
  @Input()
  set lazyLoad(val: boolean) {
    this.init = convertToBoolProperty(val);
  }
}


@Component({
  selector: 'app-tabset',
  styleUrls: ['./tabset.component.scss'],
  template: `
    <ul class="tabset">
      <li *ngFor="let tab of tabs"
          (click)="selectTab(tab)"
          (keyup.space)="selectTab(tab)"
          (keyup.enter)="selectTab(tab)"
          [class.responsive]="tab.responsive"
          [class.active]="tab.active"
          [class.disabled]="tab.disabled"
          [attr.tabindex]="tab.disabled ? -1 : 0"
          class="tab">
        <a href (click)="$event.preventDefault()" tabindex="-1" class="tab-link">
          <nb-icon *ngIf="tab.tabIcon" [config]="tab.tabIcon"></nb-icon>
          <span *ngIf="tab.tabTitle" class="tab-text">{{ tab.tabTitle }}</span>
        </a>
        <nb-badge *ngIf="tab.badgeText"
                  [text]="tab.badgeText"
                  [status]="tab.badgeStatus"
                  [position]="tab.badgePosition">
        </nb-badge>
      </li>
    </ul>
    <ng-content select="app-tab"></ng-content>
  `
})
export class TabsetComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @HostBinding('class.full-width')
  fullWidthValue = false;
  @Input() routeParam: string;
  @Output() changeTab = new EventEmitter<any>();
  private myRoute;
  private myChangeDetectorRef;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    changeDetectorRef: ChangeDetectorRef) {
    this.myRoute = route;
    this.myChangeDetectorRef = changeDetectorRef;
  }

  @Input()
  set fullWidth(val: boolean) {
    this.fullWidthValue = convertToBoolProperty(val);
  }

  ngAfterContentInit() {
    this.myRoute.queryParams
    .pipe(
      map(
        (params: any) =>
          this.tabs.find((tab) => this.routeParam ? tab.route === params[this.routeParam] : tab.active)
      ),
      delay(0),
      map((tab: TabComponent) => tab || this.tabs.first),
      filter((tab: TabComponent) => !!tab)
    )
    .subscribe((tabToSelect: TabComponent) => {
      this.selectTab(tabToSelect);
      this.myChangeDetectorRef.markForCheck();
    });
  }

  selectTab(selectedTab: TabComponent) {
    if (!selectedTab.disabled) {
      this.tabs.forEach(tab => tab.active = tab === selectedTab);
      if (this.routeParam.length > 0) {
        const queryParam = {};
        queryParam[this.routeParam] = selectedTab.route;
        this.router.navigate([], {
          relativeTo: this.myRoute,
          queryParams: queryParam,
          queryParamsHandling: 'merge'
        })
        .then(() => {
          this.changeTab.emit(selectedTab);
        })
        .catch((error) => {
          console.log(error);
          this.changeTab.emit(selectedTab);
        });
      } else {
        this.changeTab.emit(selectedTab);
      }
    }
  }
}
