import {Component, OnDestroy, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {UserService} from 'app/@core/utils/user.service';
import {Subscription} from 'rxjs';
import {AnalyticsService, LayoutService} from '../../../@core/utils';
import {SampleLayoutComponent} from '../../layouts';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  user: {
    displayName: string,
    email?: string,
    title?: string
  } = {displayName: 'dummy'};

  userMenu = [/*{title: 'Profile'}, */{title: 'Log out', link: '/auth/logout'}];
  private subscriptions: Subscription[] = [];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              private userService: UserService) {
    this.subscriptions.push(
      userService.getAuthenticatedUserInfo()
      .subscribe(user => this.user = user)
    );
    SampleLayoutComponent.addCloseSideBarAction(this.menuService, this.sidebarService);
  }

  ngOnInit() {
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
