import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {NbAuthModule, NbTokenLocalStorage, NbTokenStorage} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {environment} from '../../environments/environment';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, LayoutService, StateService} from './utils';
import {AuthADFSToken, InternalAuthStrategy} from './utils/internal-auth.strategy';
import {SMWRoleProvider} from './utils/smw-role.service';

const DATA_SERVICES = [];

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      InternalAuthStrategy.setup({
        name: 'internal',
        clientId: environment.clientId,
        clientSecret: environment.clientSecret,
        authorize: {
          endpoint: environment.fedService,
          responseType: environment.responseType,
          scope: environment.authorizeScope,
          redirectUri: environment.redirectUrl,
          params: {
            "prompt": "select_account"
          }
        },
        refresh: {
          endpoint: environment.baseUrl + '/api/auth/token',
          requireValidToken: false
        },
        token: {
          class: AuthADFSToken,
          endpoint: environment.baseUrl + '/api/auth/fed'
        },
        redirect: {
          success: '/pages/dashboard',
          failure: '/auth/login'
        }
      })
    ],
    forms: {
      validation: {
        password: {
          required: true,
          minLength: 3,
          maxLength: 12
        },
        email: {
          required: true
        }
      }
    }
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      support: {
        view: ['userProfileMenu', 'supportMenu'],
        url: [
          '/audit'
        ]
      },
      pricing_manager: {
        parent: 'support',
        view: ['pricingManagerMenu'],
        edit: ['userDisplayConfiguration'],
        url: [
          '/pages/dashboard',
          '/validation-rules',
          '/history',
          '/calculation-parameters',
          '/confirm-ta-pricing',
          '/user-management/internal',
          '/user-management/internal/edit/*',
          '/rebt'
        ]
      }
    }
  }).providers,
  {
    provide: NbTokenStorage, useClass: NbTokenLocalStorage
  },
  {
    provide: NbRoleProvider,
    useClass: SMWRoleProvider
  },
  AnalyticsService,
  LayoutService,
  StateService,
  InternalAuthStrategy
];

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    NbAuthModule
  ],
  declarations: [],
  providers: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS
      ]
    };
  }
}
