import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Validator} from '../dynamic-field';
import {SelectOptionInterface} from './select-option.interface';
import {SelectAbstract} from './select.abstract';

@Component({
  selector: 'app-select',
  template: `
    <mat-form-field class="container-fluid mt-auto" [formGroup]="group">
      <mat-select [placeholder]="label" [formControlName]="name" [compareWith]="compareObjects">
        <mat-option *ngFor="let item of options" [value]="item.value">{{item.title}}</mat-option>
      </mat-select>
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error
            *ngIf="group.get(name).hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
      <mat-error *ngIf="group.get(name).hasError('custom')">{{group.get(name).getError(
        'custom')}}</mat-error>
    </mat-form-field>
  `,
  styles: []
})
export class SelectComponent extends SelectAbstract implements OnInit, OnDestroy {
  optionsPromise?: BehaviorSubject<SelectOptionInterface[]> = new BehaviorSubject(null);
  type = SelectComponent;
  label: string;
  name: string;
  options: SelectOptionInterface[] = [];
  validations: Validator[] = [];
  disabled = false;
  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscriptions.push(
      this.optionsPromise
      .subscribe(data => {
        if (data) {
          this.options = data;
        }
      })
    );
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.toString() === o2.toString();
  }

  debugger(v: any, c) {
    console.log(v, c);
    return v;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
