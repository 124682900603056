import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertMessage, AlertMessages, AlertService} from 'app/service/alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
  message: AlertMessage;
  private subscriptions: Subscription[] = [];

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.alertService.getMessage()
      .subscribe(message => {
        if (message !== undefined) {
          if (message.type === AlertMessages.CLEAR) {
            this.message = null;
          } else {
            this.message = message;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
