<mat-form-field class="container-fluid mt-auto" [style.margin]="styles.margin" [style.padding]="styles.padding"
    [style.width]="styles.width" [formGroup]="group">
    <input matInput (click)="picker.open()" [required]="required" [matDatepicker]="picker" [formControlName]="name"
        [placeholder]="label">
    <mat-datepicker-toggle class="date-picker" matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint></mat-hint>
    <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="hasValidationError(validation)">{{validation.message}}</mat-error>
    </ng-container>
    <mat-error *ngIf="group.get(name).hasError('custom')">{{group.get(name).getError(
        'custom')}}</mat-error>
</mat-form-field>