import {Component, OnInit} from '@angular/core';
import {ButtonAbstract} from './button.abstract';

@Component(
  {
    selector: 'app-button',
    template: `
      <div class="form-group container-fluid"
           [style.margin]="styles.margin"
           [style.padding]="styles.padding"
           [style.width]="styles.width"
           [formGroup]="group">
        <button
          style="width: 180px"
          nbButton
          status="primary"
          type="{{actionType}}"
          (click)="actionHandler()"
          id="{{buttonId}}"
          class="btn btn-md btn-primary rounded-0">{{label}}</button>
      </div>
    `,
    styles: []
  }
)
export class ButtonComponent extends ButtonAbstract implements OnInit {
  type = ButtonComponent;
  label: string;
  styles?: any = {};
  actionType = 'submit';
  color = 'primary';
  appearance = 'filled';
  buttonId?: string;
  callbackScope?: any;
  callbackMethod?: (scope: any) => void;

  constructor() {
    super();
  }

  actionHandler() {
    if (typeof this.callbackMethod === 'function'
      && this.actionType !== 'submit') {
      this.callbackMethod(this.callbackScope);
    }
  }

  ngOnInit() {
  }
}
