import { EnvVars } from './env-vars';

export const environment: EnvVars = {
  production: false,
  baseUrl: 'https://backoffice.dev.wm.aws.mblty-cloud.services',
  vehicleServicePrefix: '/api-gateway',
  orderServicePrefix: '/api-gateway',
  dadServicePrefix: '/api-gateway',
  sapServicePrefix: '/api-gateway',
  apiVersion: '',
  fedService: 'https://login.microsoftonline.com/964bdbb8-b334-4412-a620-2d0cc3b18666/oauth2/v2.0/authorize',
  redirectUrl: 'https://ui.dev.wm.aws.mblty-cloud.services/auth/callback',
  callbackUrl: 'https://ui.dev.wm.aws.mblty-cloud.services',
  clientId: '443437ea-8c9b-4b7e-a29b-fcf322d98326',
  clientSecret: '',
  responseType: 'code',
  authorizeScope: 'openid'
};