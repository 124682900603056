import {Component, OnInit} from '@angular/core';
import {Validator} from '../dynamic-field';
import {InputAbstract} from './input.abstract';

@Component({
  selector: 'app-list-input',
  template: `
    <mat-form-field class="container-fluid mt-auto" [formGroup]="group">
      <input matInput
             [formControlName]="index"
             [placeholder]="label"
             [type]="inputType"
             [maxlength]="maxLength"
             [minlength]="minLength"
             (input)="onKeypress($event)"
      >
      <ng-container *ngFor="let validation of validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.controls[index].hasError(validation.name)">{{validation.message}}</mat-error>
      </ng-container>
    </mat-form-field>
  `,
  styles: []
})
export class ListInputComponent extends InputAbstract implements OnInit {
  type = ListInputComponent;
  name: string;
  label: string;
  inputType: string;
  maxLength: number;
  minLength: number;
  validations: Validator[];
  disabled: false;
  index: number;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  onKeypress(event) {
    this.group.controls[this.index].patchValue(this.group.controls[this.index].value.toUpperCase());
  }
}
